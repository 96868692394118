import React from 'react'
import PropTypes from 'prop-types'

const ListDiv = (props) => {
    return (
        <div>
            <select name={props.name} id={props.id} onClick={props.handleSelect} size='15' multiple>
                {[...Array(props.items.length)].map((e, i) => 
                <option 
                value={props.items[i]} key={props.items[i]} >
                    {props.items[i]}
                </option>)}
            </select>
        </div>
    )
}

ListDiv.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func,
}
export default ListDiv