import logo from './mainicon.svg';
import './App.css';

import React, { Component } from 'react'
import ListDiv from './components/ListDiv';
import UTMLatLng from 'utm-latlng';

export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      name_pts: [],
      name_lns: [],
      name_polys: [],
      datum: "WGS84",
      pts: {},
      lns: {},
      polys: {},
      activeText: "No coordinate to show",
    }
    this.props = props;
  }

  // ARROW FUNCTIONS ARE TO PRESERVE EXTERNAL SCOPE FOR this.setState 
  // within the onload method
  readKML = (e) => {
    /* 
      Make sure that we are reading a KML here
    */
    var reader = new FileReader();

    reader.onload = (e) => {
      var contents = e.target.result;
      
      let pts, lns, polys;
      [pts, lns, polys] = parseKML2feat(contents);
      
      this.setState({ 
        name_pts: Object.keys(pts),
        name_lns: Object.keys(lns),
        name_polys: Object.keys(polys),
        pts: pts,
        lns: lns,
        polys: polys,
      })

    }
    
    var file = e.target.files[0];
    if (file !== undefined){
      reader.readAsText(file);
    }
    
  }

  handleClickPt = (e) => {
    let rawcoords = this.state.pts[e.target.value];
    let latlongc = rawcoords[0].split(',');
    let utmc = convertToUTM(rawcoords, 'WGS84');
    
    this.setState({
      activeText: `Point ${e.target.value}: 
      \n Lat ${latlongc[1]} Long ${latlongc[0]} 
      \n UTM Zone:${utmc[0].ZoneNumber}${utmc[0].ZoneLetter}  
      \n [${utmc[0].Easting} ${utmc[0].Northing}]`
    });

  }

  handleClickLn = (e) => {
    let rawcoords = this.state.lns[e.target.value];
    let latlongc = rawcoords.map((e) => e.split(','));
    let utmc = convertToUTM(rawcoords, 'WGS84');
    
    this.setState({
      activeText: `Line ${e.target.value}` + assemblestr(latlongc, utmc)
    });
  }

  handleClickPoly = (e) => {
    let rawcoords = this.state.polys[e.target.value];
    let latlongc = rawcoords.map((e) => e.split(','));
    let utmc = convertToUTM(rawcoords, 'WGS84');

    this.setState({
      activeText: `Poly ${e.target.value}` + assemblestr(latlongc, utmc)
    })
  }
  
  render() {
    return (
      <div className="App">
      <header className="App-header">
        {//<img src={logo} className="App-logo" alt="logo" />
        }
	<img src={logo} alt="logo" />
        
        <h2> Input a KML file </h2>
        <input type="file" id="file-input" onChange={this.readKML} accept=".kml" />
        
        <div style={{
          width: "100%", 
          display: "flex", 
          flexDirection: 'row',
          justifyContent: 'center'}}>

            <ListDiv name='pointlst' items={this.state.name_pts} 
            handleSelect={this.handleClickPt}/>

            <ListDiv name='linelst' items={this.state.name_lns} 
            handleSelect={this.handleClickLn}/>

            <ListDiv name='polylst' items={this.state.name_polys} 
            handleSelect={this.handleClickPoly}/>

        </div>
        <label for="datum"> Datum: {this.state.datum} </label>
        {/*:
        NAD27 Recheck
        <input type="checkbox" id="nad27" onChange={() => this.setState({is_NAD27: !this.state.is_NAD27})} />
        */}
        <p className="display-linebreak"> {this.state.activeText} </p>
        
      </header>
      <body>
        <p>Initial Build</p>
      </body>
    </div>
    )
  }
}

function assemblestr(lstlattlong, lstutm){
  let strlist = [];
  for(let i=0;i<lstlattlong.length;i++){
    strlist.push(
      `\nLat ${lstlattlong[i][1]} Long ${lstlattlong[i][0]} 
      \nUTM Zone:${lstutm[i].ZoneNumber}${lstutm[i].ZoneLetter}  
      \n[${lstutm[i].Easting} ${lstutm[i].Northing}]`
      )
  }
  return strlist.join('\n');
}

function convertToUTM(lsttext, strdatum){
  /*
    Parse a list of string coordinates in long lat to UTM
    strdatum: anyof ["NAD27", "WGS84"]
  */
    let utm = strdatum === "NAD27" ? new UTMLatLng('Clarke 1866'): new UTMLatLng();
    let lstlatlongc = lsttext.map((i) => i.split(','));
    let utmc = lstlatlongc.map((i) => utm.convertLatLngToUtm(parseFloat(i[1]), parseFloat(i[0]), 3));
    return utmc
  }

function parseKML2feat(kmltext){
  /*
  Input: raw kml XMLString as kml text
  Returns (points, lines, polys)
  */
  var parser = new DOMParser();
  var xmlDoc = parser.parseFromString(kmltext,"text/xml");
  let totalFeatures = xmlDoc.getElementsByTagName('Placemark');
  console.log(`n_Features: ${totalFeatures.length}`);

  let n_lines = xmlDoc.getElementsByTagName('LineString').length;
  let n_polygons = xmlDoc.getElementsByTagName('Polygon').length;
  let n_markers = xmlDoc.getElementsByTagName('Point').length;

  console.log(`n polygons: ${n_polygons}`);
  console.log(`n lines: ${n_lines}`);
  console.log(`n markers: ${n_markers}`);

  let pts = {};
  let lns = {};
  let polys = {};

  for (let i=0; i<totalFeatures.length;i++){
    let featname = totalFeatures[i].getElementsByTagName('name');
    let activecoord = totalFeatures[i].getElementsByTagName('coordinates')[0].innerHTML.trim().split(' ');
    // Check if the feature has a name else throw a placeholder in there
    // TODO: This will crash if the featname happens to be unnamed_feat_i
    featname = featname.length === 0 ? `unnamed_feat_${i}` : featname[0].innerHTML;

    // Push it out to the corresponding object while catching duplicates
    if (totalFeatures[i].getElementsByTagName('LineString').length === 1){
      featname = lns.hasOwnProperty(featname) ? `feature${i}` : featname;
      lns[featname] = activecoord;
    }
    else if (totalFeatures[i].getElementsByTagName('Polygon').length === 1){
      featname = polys.hasOwnProperty(featname) ? `feature${i}` : featname;
      polys[featname] = activecoord;
    }
    else if (totalFeatures[i].getElementsByTagName('Point').length === 1){
      featname = pts.hasOwnProperty(featname) ? `feature${i}` : featname;
      pts[featname] = activecoord;
    }
  }
  
  return [pts, lns, polys];
}
